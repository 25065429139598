@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 4px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  padding: 5px 5px;
  word-break: normal;
}

.tg .tg-c3ow {
  border-color: black;
  text-align: center;
  vertical-align: middle
}

.tg .tg-7btt {
  border-color: black;
  text-align: center;
  vertical-align: middle
}

.tg .tg-0pky {
  border-color: inherit;
  text-align: left;
  vertical-align: middle
}

.tg1-bcwe,
.tg1-evkt,
.tg1-c3ow,
.tg1-7btt {
  border: 2px solid black;
  padding: 5px;
}

th {
  font-weight: 700 !important;
}