@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components{
  .formContainer{
    @apply w-[90%] lg:w-[70%] border shadow-lg my-4 mx-auto rounded-md p-5;
  }

  .heading{
    @apply text-3xl text-blue-500 font-bold mb-3 ;
  }

  .filedsContainer{
    @apply grid grid-cols-1 lg:grid-cols-2 gap-4;
  }
  .backBtn{
    @apply bg-blue-500 text-white font-semibold px-4 py-1 text-lg rounded-md flex justify-center items-center hover:bg-blue-600;
  }
}



body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  
  /* navbar css */
  .main_nav {
    max-width: 1450px;
    margin: auto;
  }
  
  .nav_bar {
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
  
  .logo {
    padding: 16px 0px;
  }
  
  .mynav_p {
    padding: 0px 35px;
  }
  
  .logo img {
    max-width: 60px;
  }
  
  .menu_items {
    width: 100%;
  
    height: 100%;
  
    padding: 21px 0px;
  }
  
  .nav_left {
    display: flex;
    width: 95%;
    height: 100%;
    align-items: center;
  }
  
  .nav_right {
    width: fit-content;
    height: 100%;
    padding: 35px 0px;
    display: flex;
    align-items: flex-end;
  }
  
  .navbar1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem;
  }
  
  .hamburger {
    display: none;
  }
  
  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #000000;
  }
  
  .nav-menu1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-family: "Cabin Condensed", sans-serif;
    padding: 0;
    margin: 0 !important;
  }
  
  .nav-item1 {
    margin-left: 0px;
    text-decoration: none !important;
  }
  
  .nav-link1 {
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
  }
  .active{
    background-color: #2563EE;
    color: white;
  }
    .Mobile_Nav{
      display: none;
    }
  
    .Desk_Nav{
      display: block;
    }
    
    @media only screen and (max-width: 768px) {
      
      .Desk_Nav{
        display:none;
      }
    .Mobile_Nav{
      display: block;
    }
  
    .nav-menu1 {
      position: fixed;
      left: -100%;
      top: 5rem;
      flex-direction: column;
      background-color: #fff;
      width: 100vw;
      height: fit-content;
      z-index: 10;
      border-radius: 10px;
      text-align: center;
      transition: 0.3s;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    }
  
    .logo img {
      max-width: 80px;
    }
  
    .logo {
      padding: 0px;
    }
    .menu_items {
      padding: 0px;
    }
    .nav-menu1.myactive {
      left: 0;
    }
  
    .main_nav {
      padding: 21px 0px;
    }
  
    .navbar1 {
      justify-content: flex-end;
    }
  
    .nav-menu1 {
      padding: 10px 0px;
    }
  
    .nav-item1 {
      padding: 15px 20px;
      width: 100%;
      border-bottom: 1px solid #eaeaea;
    }
  
    .hamburger {
      display: block;
      cursor: pointer;
    }
  
    .hamburger.myactive .bar:nth-child(2) {
      opacity: 0;
    }
  
    .hamburger.myactive .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }
  
    .hamburger.myactive .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  
    .cta {
      display: none;
    }
  
    .mynav_p {
      padding: 0px 20px;
    }
  
    .nav_left {
      width: 100% !important;
    }
    .count{
      display: none;
    }
  
    .main_content{
      flex-direction: column;
      align-items: baseline !important;
    }
  }
  
  .main_nav {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  
  
  /* loader css*/
  .loader {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  .loader2 {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin2 2s linear infinite;
  }
  
  @keyframes spin2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  
  /* Table css */
  /* .tg  {border-collapse:collapse;border-spacing:0;}
  .tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:12px;
    overflow:hidden;padding:10px 5px;word-break:normal;}
  .tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:12px;
    font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
  .tg .tg-baqh{text-align:center;vertical-align:top}
  .tg .tg-c3ow{border-color:inherit;text-align:center;vertical-align:top}
  .tg .tg-0pky{border-color:inherit;text-align:left;vertical-align:top}
  .tg .tg-dvpl{border-color:inherit;text-align:right;vertical-align:top} */